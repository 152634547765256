import { Outlet, useRouteLoaderData } from "@remix-run/react";

import type { loader } from "../root";
import { BaseNav } from "~/modules/layout/TopNav";

export default function AuthLayout() {
  const data = useRouteLoaderData<typeof loader>("root");
  return (
    <div className="flex h-screen w-screen flex-col">
      <BaseNav logoURL={data?.logoURL} />
      <div className={`flex w-full flex-grow justify-center`}>
        <Outlet />
      </div>
    </div>
  );
}
